export const projectsList = [
  {
    highlight: true,
    date: '2024',
    name: 'Privacy-Preserving Machine Learning with Medical Images',
    slug: 'opacus-vs-fast-differential-privacy',
    tagline: 'Performance and Privacy analysis of Opacus and Fast Differential Privacy in Medical Imaging.'
  },
  {
    date: '2024',
    name: 'Distributed Systems',
    slug: 'distributed-systems',
    tagline: 'Implementation of examples across communication, databases, high performance computing and privacy.'
  },
  {
    highlight: true,
    date: '2024',
    name: 'Multimedia Information Retrieval Techniques',
    slug: 'Multimedia-Information-Retrieval',
    tagline: 'Transformed data into insights with classifier evaluation, Lucene indexing, NLP, embeddings, RAG, and decision tree visualizations.'
  },
  {
    highlight: true,
    date: '2024',
    name: 'Comparitive Study of Gradinet Clipping Techniques',
    slug: 'automatic-clipping-vs-random-sparsification',
    tagline: 'A Comparative Study of Gradient Clipping Techniques in Differentially Private Stochastic Gradient Descent (DP-SGD).'
  },
  {
    highlight: true,
    date: '2023',
    name: 'Patter Recognition Algorithms',
    slug: 'Pattern-Recognition',
    tagline: 'Implemented algorithms with perceptron, classification, regression, boundary detection, image restoration, and deep neural network feature design.'
  },
  {
    highlight: true,
    date: '2021',
    name: 'Loan Approval Prediction',
    url: 'https://youtu.be/tz_yVYjfyW4',
    slug: 'Loan-Approval-Prediction',
    tagline: 'Prediction of loan approval based on applicant details using logistic regression model.'
  },
  {
    date: '2021',
    name: 'pip purge',
    url: 'https://pypi.org/project/pip-purge/',
    slug: 'pip-purge',
    tagline: 'A python package which uninstalls everything in your virtualenv enviornment',
  },
  {
    highlight: true,
    date: '2020',
    name: 'Ali Scraper',
    slug: 'Ali-Scraper',
    tagline: 
    'A free, open url scraper for Ali Express integrated with Google Spreadsheet helping drop-shipping business.',
  },
  {
    name: 'background',
    date: '2019',
    url: 'https://pypi.org/project/background/',
    slug: 'background',
    tagline: 
    'A Python package which makes very simple to run things in the background of a python application, be it a CLI app, or a web app',
  },
  {
    date: '2019',
    name: 'pip plant',
    url: 'https://pypi.org/project/pip-plant',
    slug: 'plant',
    tagline: 'pip plant is similar to what is npm install in javascript',
  },
  {
    date: '2018',
    name: 'Perceptron Coding Challenge',
    slug: 'Pytorch-Challenge',
    tagline: 'A simple perceptron implementation.'
  },
  {
    date: '2018',
    name: 'Bookmark Server',
    slug: 'Bookmark-server',
    tagline: 'An URL-shortening service, similar to TinyURL.com or goo.gl, but with no persistent storage.'
  },
  {
    date: '2018',
    name: 'Tic Tac Toe',
    slug: 'tic-tac-toe',
    tagline: 'A simple game of Tic Tac Toe, built using python.'
  },
  {
    date: '2017',
    name: 'Celebrity Match',
    slug: 'CelebrityMatch',
    tagline: 'Shows top 5 traits shared between the two Twitter(X) accounts. For example: you and a celebrity of your choice.'
  }
]
